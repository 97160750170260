/* Shadow removal */
.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-success.dropdown-toggle:focus {
    box-shadow: none !important;
}

/* Effects when clicking input & cutting the length of the text input */
input[type=search]:focus::-webkit-input-placeholder {
    color: transparent !important;
    text-overflow: ellipsis;
}

input[type=search]:focus:-moz-placeholder {
    color: transparent !important;
    text-overflow: ellipsis;
}

input[type=search]:focus::-moz-placeholder {
    color: transparent !important;
    text-overflow: ellipsis;
}

input[type=search]:focus:-ms-input-placeholder {
    color: transparent !important;
    text-overflow: ellipsis;
}
