/* All media */

@media screen and (max-width: 1600px) {
  .reports .reports-list .responsible-reports {
    width: 50% !important;
  }
  .reports .reports-list .general-reports {
    width: 50% !important;
  }
  .ant-table-tbody > tr > td, tbody > tr > td {
    //padding: 0px !important;
  }
  .add-event-line_wide .two-line {
    width: 31.8% !important;
  }
  .one-events-calendar .ant-fullcalendar-selected-day .ant-fullcalendar-value::after {
    top: -5px !important;
    right: -5px !important;
    background: #F02004 !important;
  }
  .date-button-block .ant-select {
    position: static !important;
  }
  .month-name {
    position: static !important;
    margin-left: 50px;
    margin-top: 10px;
  }
  .content .date-button-block .date-tabs {
    padding-top: 35px !important;
}
  .add-event-line_wide .two-line-phone {
    width: 16% !important;
  }
  .add-event-group .new-user {
    padding-left: 10px !important;
    padding-right: 10px !important;
    margin-left: 20px !important;
  }
  .add-event-group .new-user:after {
    left: -10px !important;
  }
  .ant-fullcalendar-tbody .ant-fullcalendar-value {
    font: normal 400 12px/24px "Montserrat", sans-serif !important;
    padding: 0px !important;
  }
  .one-events-button {
    padding: 15px 45px !important;
  }
  .right-buttons {
    align-items: flex-start !important;
    justify-content: flex-start !important;
    flex-direction: column;
  }
  .right-button-event {
    max-width: 100% !important;
    min-width: 100% !important;
    margin-bottom: 25px;
  }
}

@media screen and (max-width: 1440px) {
  .wall .scrolling-list__one-user .info {
    min-width: 400px !important;
  }
  .chat-block {
    padding: 0 !important;
  }
  .add-event-line_wide .two-line {
    width: 33.3% !important;
  }
  .add-event-line_wide .two-line-phone {
    width: 33.3% !important;
  }
  .reminder .reminder-all {
    max-height: 115px !important;
  }
  .add-event-line .add-event-name {
    width: 45% !important;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .add-event-line .add-event-group {
    width: 55% !important;
  }
  .add-event-line_wide .add-event-name {
    width: 27% !important;
  }
  .add-event-line_wide .add-event-group {
    width: 73% !important;
  }
}

@media screen and (max-width: 1366px) {
  .calendar-day__one-day {
    width: 60%!important;
  }
  .calendar-day__calendar-info {
    width: 40% !important;
    padding: 15px !important;
  }
  .add-event-line {
    width: 100% !important;
    justify-content: flex-start !important;
  }
  .add-event-line .add-event-name {
    width: 25% !important;
    padding-right: 0 !important;
    display: inline-block !important;
  }
  .add-event-group {
    width: 50% !important;
  }
  .add-event-line_wide .add-event-name {
    width: 25% !important;
  }
  .add-event-line_wide .add-event-group {
    width: 75% !important;
  }
}

@media screen and (max-width: 1330px) {
  .add-event-line .add-event-name {
    width: 35% !important;
  }
  .add-event-group {
    width: 65% !important;
  }
  .add-event-line_wide .add-event-name {
    width: 35% !important;
  }
  .add-event-line_wide .add-event-group {
    width: 65% !important;
    position: relative;
    padding-bottom: 50px;
  }
  .add-event-group .add-new-user {
    margin-left: 0 !important;
  }
  .add-event-line_wide .add-event-group-center {
    padding-bottom: 0 !important;
  }
}

@media screen and (max-width: 1280px) {
  .content .date-button-block .date-tabs .date-one-tab {
    padding: 10px 25px !important;
    margin: 0 5px !important;
  }
  .color-users-row__user .color-users-line .color-users-one::after {
    display: none !important;
  }
  .color-users-row__user .color-users-line .color-users-one {
    padding-left: 20px !important;
  }
  .color-users-row__color .color-users-one::after {
    display: none !important;
  }
  .color-users-row__color .color-users-one {
    padding-left: 20px !important;
  }
  .col-calendar {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .add-event-line .add-event-group {
    width: 75% !important;
  }
  .add-event-line_wide .add-event-group {
    width: 68% !important;
  }
  .add-event-name {
    display: inline-block !important;
  }
  .event-info-all span {
    display: block;
  }
  .color-users-row__color{
    padding-left: 10px !important;
  }
}

@media screen and (max-width: 1200px) {
  .add-event-step2 .step2-second-block {
    flex-direction: column !important;
  }
  .add-event-step2 .step2-second-block .table-first-block-step2 {
    width: 100% !important;
    padding-right: 0px !important;
    padding-bottom: 25px;
  }
  .add-event-step2 .step2-second-block .table-second-block-step2 {
    left: 0;
    position: relative !important;
    width: 100% !important;
    padding-left: 0px !important;
  }
  .add-event-step2 .step2-second-block .table-first-block-step2::after {
    top: auto !important;
    right: 0 !important;
    left: 0 !important;
    width: 100% !important;
    height: 1px !important;
    bottom: 0;
  }
  .add-event-step3 .step3-first-block__line {
    flex-direction: column !important;
    align-items: flex-start !important;
  }
  .add-event-step3 .step3-first-block__line .step3-block-row {
    width: 100% !important;
    margin-bottom: 15px !important;
  }
  .add-event-step3 .step3-first-block__line .step3-block-row:last-child {
    margin-bottom: 0 !important;
  }
}

@media screen and (max-width: 991px) {
  .top-header .right-side__log-out {
    padding-left: 90px !important;
  }
  .row-events{
    flex-direction: column-reverse;
  }
  .one-events-button {
    margin-bottom: 50px;
  }
  .date-one-events .date {
    flex-direction: column !important;
    align-items: flex-start !important;
  }
  .date-one-events .date .week-day {
    margin-left: 0px !important;
  }
  .right-button-event {
    max-width: 220px !important;
    min-width: 100px !important;
    width: 100%;
  }
  .one-event-place {
    flex-direction: column !important;
  }
  .event-info-all {
    width: 100% !important;
  }
  .one-event-line {
    flex-direction: column !important;
  }
}

@media screen and (max-width: 768px) {
  .chat-block {
    padding: 50px 0 0 !important;
  }
  .wall .scrolling-list__one-user .info {
    min-width: 100% !important;
  }
  .reports .border-line {
    display: none;
  }
  .reports .reports-list {
    flex-direction: column !important;
  }
  .reports .reports-list .general-reports {
    width: 100% !important;
    padding-right: 0 !important;
  }
  .reports .reports-list .responsible-reports {
    width: 100% !important;
    margin-top: 50px;
    padding-left: 0 !important;
  }
  .left-slide-menu {
    max-width: 300px !important;
  }
  .top-header .left-side__name-page {
    font: normal 600 24px/37px Montserrat, sans-serif !important;
  }
  .top-header .right-side__add-event {
    padding: 10px !important;
  }
  .top-header .right-side__log-out {
    padding-left: 50px !important;
  }
  .content {
    left: 300px !important;
    width: calc(100% - 300px) !important;
  }
  .reminder .reminder-all .reminder-one {
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
  }
  .reminder .reminder-all .reminder-one .icon-reminder {
    margin-right: 0px !important;
  }
  .reminder .reminder-all .reminder-one .reminder-date {
    position: relative !important;
    right: 0 !important;
  }
  .reminder .reminder-all {
    max-height: 65px !important;
  }
  .add-event-line {
    flex-direction: column !important;
    align-items: flex-start !important;
  }
  .add-event-line .add-event-name {
    width: 100% !important;
    margin-bottom: 5px;
  }
  .add-event-line .add-event-group {
    width: 100% !important;
  }
  .add-event-line .add-event-group {
    flex-direction: column !important;
  }
  .add-event-group .new-user {
    margin-top: 15px;
    margin-left: 0px !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .add-event-group .new-user:after {
    display: none;
  }
  .add-event-line_wide {
    flex-direction: column !important;
  }
  .add-event-line_wide .add-event-name {
    margin-bottom: 5px;
    width: 100% !important;
  }
  .add-event-line_wide .add-event-group {
    width: 100% !important;
  }
}

.add-event-group .add-new-user.printed-btn:disabled {
  color: #3768B4;
}

.modal-title-printed {
  margin-bottom: 24px;
}

.text-printed {
  padding-left: 20px;
}

.label-printed {
  vertical-align: middle;
  cursor: pointer;
}